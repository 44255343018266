import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="">
      <div className="footer-holder">
        <footer className="footer">
          <div className="container border-t ">
            <div className="footer-grid">
              <div>
                <div className="ml-[-12px]">
                  <img
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1714663442/logo-ai-africa_feiiog.svg"
                    alt="logo"
                    width={180}
                  />
                  {/* <img
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734016115/AI_Africa_Christmas_Logo_-_WHITE_imo8ws.png"
                    alt="logo"
                    width={180}
                  /> */}
                </div>
                <div>
                  <div className="icons">
                    <a
                      href="https://www.linkedin.com/company/2699628"
                      target="_blank"
                      rel="noreferrer"
                      title="linkedin"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/analyticsintelligence/"
                      target="_blank"
                      rel="noreferrer"
                      title="instagram"
                    >
                      <i className="fa-brands fa-square-instagram"></i>
                    </a>
                    <a
                      href="https://twitter.com/AnalyticsIntel_"
                      target="_blank"
                      rel="noreferrer"
                      title="twitter"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a
                      href="https://www.facebook.com/Analyticsintel/"
                      target="_blank"
                      rel="noreferrer"
                      title="facebook"
                    >
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <h1 className="footer-title">products</h1>
                <ul>
                  <li>
                    <a
                      href="https://powerdby.ai/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Powerdby[AI]
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://aiacademy.africa/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      [AI] Academy Africa
                    </a>
                  </li>
                  <li className="">
                    <a
                      href="https://dataassistant.ai/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Data Assistant
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h1 className="footer-title">learn</h1>
                <ul>
                  <li>
                    <Link to="/corporate-training/ai" reloadDocument>
                      Corporate Training
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://aiacademy.africa/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      [AI] Academy Africa
                    </a>
                  </li>
                  <li>
                    <Link reloadDocument to="/blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link reloadDocument to="/event">
                      Event
                    </Link>
                  </li>
                  <li>
                    <Link reloadDocument to="/faqs">
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h1 className="footer-title">developers</h1>
                <ul>
                  <li>
                    <a
                      href="https://powerdby.ai/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Powerdby[AI]
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h1 className="footer-title">company</h1>
                <ul>
                  <li>
                    <Link reloadDocument to="/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link reloadDocument to="/careers">
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link reloadDocument to="/community">
                      Community
                    </Link>
                  </li>
                  <li>
                    <Link reloadDocument to="/privacy">
                      Privacy policy
                    </Link>
                  </li>
                  {/* <li>
                  <Link reloadDocument to="/terms">
                    Terms and conditions
                  </Link>
                </li> */}
                </ul>
              </div>
              <div className="footer-subgrid">
                <h1 className="footer-title">contact</h1>
                <ul>
                  <li>info@analyticsintelligence.com</li>
                  <li>sales@analyticsintelligence.com</li>
                  <li>support@analyticsintelligence.com</li>
                  <li>careers@analyticsintelligence.com</li>
                </ul>
              </div>
            </div>
            <p className="w-full mt-[150px]">
              © Copyright {new Date().getFullYear()} [AI] Analytics Intelligence
              Inc. All Rights Reserved
            </p>
          </div>
        </footer>
        <style jsx="true">{`
        .footer-top {
          background-color: #fff;
          background-repeat: no-repeat;
          background-position: 75% 20%;
        }
        .footer-top .container {
          padding-top: 7rem;
          padding-bottom: 7rem;
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .footer-top .container h2 {
          text-align: center;
          font-size: 1.8rem;
        }
        .footer-top .btn-base {
          padding: 1rem 5rem;
          margin-top: 2rem;
          font-size: 1.2rem;
        }
        .icons {
          font-size: 30px;
          color: #fff;
        }
        .icons a {
          margin-right: 10px;
          color: #fff;
          margin-top: 1rem;
          margin-bottom: 1rem;
          display: inline-block;
        }
        .footer {
          padding-bottom: 1rem;
          color: rgba(255, 255, 255, 0.8);
        }
        .footer-grid {
          display: flex;
          margin-bottom: 2rem;
          margin-top: 2rem;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .footer-subgrid {
          display: flex;
          flex-direction: column
          gap-[2rem]
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .footer-subgrid > * {
          flex-basis: 320px;
        }
        .footer-grid > * {
          flex-basis: 190px;
        }
        .footer-title {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 1.1rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          color: #fff;
        }
        .footer-subtitle {
          font-weight: bold;
          font-size: 1rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          color: #fff;
        }
        .footer p {
          font-size: 1rem;
        }
        .footer ul {
          list-style: none;
          padding: 0px;
        }
        .footer ul li a {
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
          display: inline-block;
          margin-right: 1rem;
        }
        .footer a {
          text-decoration: none;
          color: inherit;
        }
        .footer .twin-house {
          flex-basis: 320px;
        }
        .footer .twin {
          display: flex;
          align-items: flex-start;
        }
        .footer .twin ul {
          display: inline-block;
        }
        .footer .twin ul:last-of-type {
          margin-left: 2rem;
        }
      `}</style>
      </div>
    </div>
  );
}
